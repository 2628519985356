import { entity, persistence } from 'simpler-state';
import { Auth } from './auth_module';
import axios from 'axios';

const api = axios.create({
    baseURL: process.env.GATSBY_API_URL,
    timeout: 2000,
});

export const Keys = entity({
    loading: false,
    error: false,
    keys: null,
    activeEnv: 'TEST',
    activeKey: null,
    errorMsg: null,
}, [
    persistence('keys', { storage: 'session' }),
])

export const fetchKeys = async (orgId, env) => {

    const result = await api.get(`/organizations/${orgId}/ApiKeys`, { withCredentials: true });
    if (result.status !== 200) {
        Keys.set({ ...Keys.get(), error: true, loading: false, errorMsg: result.status })
    } else {
        const d = result.data;
        Keys.set({ ...Keys.get(), activeKey: d.find(e => e.Env === env).Key, keys: d })

    }
}