import { entity, persistence } from 'simpler-state';
import axios from 'axios';
import { Keys } from './keys_module';

const api = axios.create({
    baseURL: process.env.GATSBY_API_URL ?? "http://localhost:8080/",
    timeout: 6000,
});

export const Auth = entity({
    loading: false,
    loggedIn: false,
    error: false,
    user: null,
    user_id: null,
    errorMsg: null,
    activeOrg: null,
}, [
    persistence('user', { storage: 'session' }),
])

export const logout = async () => {
    console.log("Loggging out...")
    const result = api.post('/users/auth/logout', {}, { withCredentials: true });
    Auth.set({});
}

export const fetchUser = async () => {
    const result = await api.get(`/users/me`, { withCredentials: true });
    if (result.status !== 200) {
        Auth.set({ ...Auth.get(), loading: false, erorr: true, errorMsg: result.status })
    } else {
        const orgId = Auth.get().activeOrg
        const data = result.data
        if (orgId == null) {
            if (data.Organizations)
                Auth.set({ ...Auth.get(), activeOrg: data.Organizations[0].Organization.Uid })
        }
        Auth.set({ ...Auth.get(), user: data })
    } 
}

export const loginUser = async ({email, password}) => {
    Auth.set({ ...Auth.get(), loading: true })
    const data = {email, password}
    // logout();
    
    const result = await api.post('/users/auth/login', data, { withCredentials: true });
    if (result.status !== 200) {
        Auth.set({ loading: false, error: true, errorMsg: result.status })
    } else {
        Auth.set({ user_id: result.data.UUID, user: result.data, loggedIn: true })
    }
}

export const registerUser = async (data) => {
    logout();
    
    const result = await api.post('/users/auth', data, { withCredentials: true });
    if (result.status !== 200) {
        // Auth.set({  ...Auth.get(), loading: false, error: true, errorMsg: result.status })
        return null;
    } else {
        const d = result.data;
        // Auth.set({  ...Auth.get(), user_id: result.data.UUID, user: result.data, loggedIn: true })
        return d;
    }
}
