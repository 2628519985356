import { navigate } from 'gatsby';
import React, { useEffect } from 'react';
import { Auth, logout } from '../../modules/auth_module';

const Logout = () => {
    const auth = Auth.use();

    useEffect(() => {
        logout();
        setTimeout(() => {
            navigate("/app/login")
        }, 1000)
    }, [])

    return(
        <>
            Logging Out
        </>
    )
}

export default Logout